<template>
  <header>
    <h1>Oferty</h1>
    <h4>konfigurator ofert</h4>
  </header>
  <div class="row">
    <div class="col-50 mr-1">
      <base-card>
        <template #header>Konfiguracja oferty</template>
        <template #content>
          <offer-list
            :taxVal="taxVal"
            @valueForCalc="setValueForCalc"
          ></offer-list>
        </template>
      </base-card>
    </div>
    <div class="col-50">
      <base-card>
        <template #header>Kalkulator podatkowy</template>
        <template #content>
          <tax-calc
            @tax-val="updateTax"
            :initialValue="initialCalcValue"
          ></tax-calc>
        </template>
      </base-card>
    </div>
  </div>
</template>

<script>
import OfferList from "../components/offers/OfferList.vue";
import TaxCalc from "../components/taxCalc/TaxCalc.vue";
export default {
  components: { OfferList, TaxCalc },
  data() {
    return {
      initialCalcValue: 0,
      taxVal: 8.5,
    };
  },
  methods: {
    setValueForCalc($event) {
      this.initialCalcValue = $event;
    },
    updateTax($event) {
      this.taxVal = $event;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../_base.scss";
</style>