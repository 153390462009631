<template>
  <form @submit.prevent="goToSummary">
    <offer-item
      data-test="new-offer"
      @delete-item="deleteItem($event)"
      @update-list="updateList($event)"
      v-for="item of items"
      :key="item.id"
      :item="item"
      :dims="allDimensions"
      :hasItems="hasItems"
    ></offer-item>
    <div class="error" v-if="errorMsg !== ''">
      <p>{{ errorMsg }}</p>
    </div>
    <div class="my-1">
      <base-button
        type="button"
        data-test="add-button"
        :btnType="'primary'"
        @click="addItem"
        >Dodaj pozycje +</base-button
      >
    </div>
    <div>
      <base-button type="submit" data-test="submit-button" :btnType="'submit'"
        >Przejdź do podsumowania</base-button
      >
    </div>
  </form>
  <div class="border mt-1 pt-1">
    <div class="resume">
      <div>Ostateczna cena za m^2:</div>
      <div>{{ countM2 }}</div>
    </div>
    <div class="resume">
      <div>Ostateczna cena za sztuki:</div>
      <div>{{ countPieces }}</div>
    </div>
    <div class="resume">
      <div>Ostateczna cena za całość:</div>
      <div>{{ countAll }}</div>
    </div>
  </div>
</template>

<script>
import OfferItem from "./OfferItem.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { OfferItem },
  emits: ["valueForCalc"],
  props: ["taxVal"],
  data() {
    return {
      items: [],
      hasItems: false,
      errorMsg: "",
    };
  },
  watch: {
    countAll(newVal) {
      this.$emit("valueForCalc", newVal);
    },
  },
  computed: {
    ...mapGetters(["getOffer"]),
    allDimensions() {
      return ["m^2", "sztuki"];
    },
    countM2() {
      let sum = 0;
      this.items.forEach((item) => {
        if (item.dimension === "m^2") {
          sum += item.price * item.quantity;
        }
      });
      return sum;
    },
    countPieces() {
      let sum = 0;
      this.items.forEach((item) => {
        if (item.dimension === "sztuki") {
          sum += item.price * item.quantity;
        }
      });
      return sum;
    },
    countAll() {
      return this.countM2 + this.countPieces;
    },
  },
  methods: {
    ...mapActions(["setOffer"]),
    count() {
      this.sumM2 = this.countM2();
      this.countPieces();
      this.countAll();
    },
    deleteItem($event) {
      this.items = this.items.filter((item) => item.id !== $event);
    },
    updateList($event) {
      const foundIndex = this.items.findIndex((item) => item.id === $event.id);
      this.items[foundIndex] = $event;
    },
    addItem() {
      this.items.push({
        id:
          this.items.length === 0
            ? 0
            : Math.max.apply(
                Math,
                this.items.map(function (item) {
                  return item.id;
                })
              ) + 1,
        name: "",
        quantity: 0,
        dimension: "m^2",
        price: 0,
        valid: true,
      });
    },
    validateForm() {
      let invalidItem = this.items.find((item) => {
        return item.valid === false;
      });
      if (invalidItem) {
        this.errorMsg = `Element o id: ${invalidItem.id} jest nieprawidłowy. Popraw go przed zatwierdzeniem formularza.`;
        return false;
      } else {
        this.errorMsg = "";
        return true;
      }
    },
    goToSummary() {
      if (this.validateForm()) {
        this.setOffer({ items: this.items, taxVal: this.taxVal });
        this.$router.push("/summary");
      }
    },
  },
  mounted() {
    if (this.getOffer.items.length > 0) {
      this.items = [];
      this.items = [...this.getOffer.items];
    } else {
      this.addItem();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../_base.scss";
.resume {
  display: flex;
}
.error {
  color: $red;
}
.border {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>