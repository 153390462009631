<template>
  <div class="form-group">
    <div class="row col-90">
      <div class="form-control mr-1">
        <label :for="itemName">Nazwa</label>
        <input
          type="text"
          :name="itemName"
          :id="itemName"
          :placeholder="'Nazwa'"
          v-model="selectedName"
          @keyup="
            validateName();
            updateList();
          "
          :class="{ invalid: !isNameValid }"
        />
      </div>
      <div class="form-control mr-1">
        <label :for="itemQuantity">Ilość</label>
        <input
          type="number"
          :name="itemQuantity"
          :id="itemQuantity"
          :placeholder="item.quantity"
          v-model="selectedQuantity"
          step="0.01"
          @keyup="
            validateQuantity();
            updateList();
          "
          :class="{ invalid: !isQuantityValid }"
        />
      </div>
      <div class="form-control mr-1">
        <label :for="itemDimension">Wymiar</label>
        <select
          :name="itemDimension"
          :id="itemDimension"
          v-model="selectedDimension"
          @change="updateList()"
        >
          <option v-for="dim in dims" :key="dim" :value="dim">
            {{ dim }}
          </option>
        </select>
      </div>
      <div class="form-control mr-1">
        <label :for="itemPrice">Cena za {{ selectedDimension }}</label>
        <input
          type="number"
          :name="itemPrice"
          :id="itemPrice"
          :placeholder="item.price"
          v-model="selectedPrice"
          step="0.01"
          @keyup="
            validatePrice();
            updateList();
          "
          :class="{ invalid: !isPriceValid }"
        />
      </div>
    </div>
    <base-button
      @click="deleteItem(item.id)"
      :btnType="'remove'"
      v-if="item.id !== 0"
      >-</base-button
    >
  </div>
</template>

<script>
export default {
  props: ["item", "dims", "hasItems"],
  data() {
    return {
      selectedDimension: this.item.dimension || "m^2",
      selectedName: this.item.name || "",
      selectedQuantity: this.item.quantity || 0,
      selectedPrice: this.item.price || 0,
      selectedId: this.item.id || 0,
      isNameValid: true,
      isQuantityValid: true,
      isPriceValid: true,
      isValid: false,
    };
  },
  computed: {
    itemName() {
      return this.item.name + "-" + this.item.id;
    },
    itemQuantity() {
      return this.item.name + "-quantity-" + this.item.id;
    },
    itemDimension() {
      return this.item.name + "-dimension-" + this.item.id;
    },
    itemPrice() {
      return this.item.name + "-price-" + this.item.id;
    },
    itemCustomized() {
      return {
        id: +this.item.id,
        name: this.selectedName,
        quantity: +this.selectedQuantity,
        dimension: this.selectedDimension,
        price: +this.selectedPrice,
        valid: this.isNameValid && this.isQuantityValid && this.isPriceValid,
      };
    },
  },
  methods: {
    deleteItem(itemId) {
      this.$emit("delete-item", itemId);
    },
    updateList() {
      this.$emit("update-list", this.itemCustomized);
    },
    validateName() {
      if (this.selectedName.trim() === "" || this.selectedName.length < 3) {
        this.isNameValid = false;
      } else {
        this.isNameValid = true;
      }
    },
    validateQuantity() {
      if (this.selectedQuantity === "0" || !this.selectedQuantity) {
        this.isQuantityValid = false;
      } else {
        this.isQuantityValid = true;
      }
    },
    validatePrice() {
      if (this.selectedPrice === "0" || !this.selectedPrice) {
        this.isPriceValid = false;
      } else {
        this.isPriceValid = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../_base.scss";
.form-group {
  align-items: baseline;
}
.invalid {
  border-color: $red !important;
}
</style>